import styles from './index.module.scss';
import SanityImage from "../../components/utilities/sanityImage";
import { getFile } from "@sanity/asset-utils";
import sanityProject from "../utilities/sanityProject";

export default function Map({content}) {

    let videoFile = null;
    let videoFileUrl = null;
    let customMedia = false;
    if (content.video_file) {
        videoFile = getFile(content.video_file, sanityProject);
        videoFileUrl = videoFile.asset.url;
        customMedia = true;
    }
    if (content.image) {
        customMedia = true;
    }

    // if no image or video is provided, default to original
    if (!customMedia) {
        videoFileUrl = "/videos/US-COVERAGE-0003.mp4";
    }

    // default numbers if not entered in CMS
    let states = content.states ? content.states : '50';
    let screens = content.screens ? content.screens : '30K';
    let views = content.views ? content.views : '2B';

    return (
        <div className={styles.map}>
            <div className={styles.wrap}>
                <div className={styles.col_stats}>
                    <div className={styles.stat}>
                        <h2>{states}</h2>
                        <div className={styles.label}>
                            <p>active</p>
                            <p>states</p>
                        </div>
                    </div>
                    <div className={styles.stat}>
                        <h2>{screens}</h2>
                        <div className={styles.label}>
                            <p>active</p>
                            <p>screens</p>
                        </div>
                    </div>
                    <div className={styles.stat}>
                        <h2>{views}</h2>
                        <div className={styles.label}>
                            <p>monthly</p>
                            <p>views</p>
                        </div>
                    </div>
                </div>
                {customMedia ?
                    <div className={styles.col_video}>
                        {videoFile == null ? (
                            <div className={styles.img_wrap}>
                                <SanityImage image={content.image} alt="Map Image" />
                            </div>
                        ) : (
                            <video autoPlay loop playsInline muted>
                                <source src={videoFileUrl} />
                            </video>
                        )}
                    </div>
                :
                    <div className={styles.col_video}>
                        <video autoPlay loop playsInline muted>
                            <source src={videoFileUrl} />
                        </video>
                    </div>
                }
            </div>
        </div>
    )
}
