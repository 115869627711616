import Link from "next/link";
import SanityImage from "./sanityImage";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";

const myPortableTextComponents = {
  types: {
    image: ({ value }) => {
      return (
        <div className={"inline_image align_" + value.align}>
          <SanityImage image={value.asset} />
        </div>
      );
    },
    youtube: ({ value }) => {
      const { url } = value;
      const id = getYouTubeId(url);
      return (
        <div className="inline_video">
          <YouTube videoId={id} containerClassName={"youtubeContainer"} />
        </div>
      );
    },
  },
  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith("/")
        ? "noreferrer noopener"
        : undefined;
      return (
        <a href={value.href} rel={rel} className={value.button ? 'btn' : ''}>
          {children}
        </a>
      );
    },
    internalLink: ({ children, value }) => {
      const { slug = {} } = value;
      const href = `/${slug.current}`;
      return (
        <Link href={href}>
          <a>{children}</a>
        </Link>
      );
    },
    color: ({ children, value }) => {
      const { hex } = value;
      return <span style={{ color: hex }}>{children}</span>;
    },
  },
};

export default myPortableTextComponents;
