import Image from "next/image";
import SanityImage from "../utilities/sanityImage";
import styles from "./hero.module.scss";
import TVframe from "/public/images/TV_frame_v2.png";
import loopLogo from "/public/images/Loop-Logo-White.jpeg";
import loopPlayer from "/public/images/Loop_Player_small_flat.png";
import rewardsPromo from "/public/images/cash_rewards_CTA.png";
import { getFile } from "@sanity/asset-utils";
import sanityProject from "../utilities/sanityProject";
import { gsap, ScrollTrigger } from "../utilities/gsap";
import { useEffect, useRef } from "react";
import { useRouter } from "next/router";

import Restaurant from "../../components/hero/restaurant.js";
import Retail from "../../components/hero/retail.js";
import Studio from "../../components/hero/studio.js";
import Home from "../../components/hero/home.js";

export default function Hero({ click, content }) {
  const router = useRouter();
  const routerPath = router.asPath;
  let showPlayer = true;
  if (routerPath === "/loop-at-home") {
    showPlayer = false;
  }

  let videoFile = null;
  if (content.video_file) {
    videoFile = getFile(content.video_file, sanityProject);
  }

  let blockClass = `${styles.heroWrapper}`;

  // text animation references
  let hasHeadlines = false;
  if (content.headlines) {
    hasHeadlines = true;
  }
  if (!hasHeadlines) {
    blockClass += ` ${styles.noheadlines}`;
  }
  const headlineRefs = useRef([]);
  const blockRef = useRef();
  const playerRef = useRef();

  function removeActiveHeadline() {
    headlineRefs.current.forEach((headline) => {
      if (typeof headline != "undefined" && headline != null) {
        headline.classList.remove(styles.active);
      }
    });
  }

  useEffect(() => {
    // signage animation on scroll

    if (innerWidth > 896) {
      if (showPlayer) {
        // gsap animations
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(playerRef.current, {
          y: 250,
          scrollTrigger: {
            trigger: blockRef.current,
            start: "top top",
            end: "bottom top",
            scrub: 1,
            markers: false,
          },
        });
      }
    }

    let count = 1; // starting with 2nd item because first is on for page load

    if (headlineRefs.current.length > 0) {
      // set first headline to active
      headlineRefs.current[0].classList.add(styles.active);

      const intervalId = setInterval(cycleHeadlines, 1750);

      return () => clearInterval(intervalId);
    }

    // loop through headline Refs, animate text
    function cycleHeadlines() {
      removeActiveHeadline();
      let headline = headlineRefs.current[count];
      if (typeof headline != "undefined" && headline != null) {
        headline.classList.add(styles.active);
      }

      count++;

      if (count === headlineRefs.current.length) {
        count = 0;
      }
    }
  }, [showPlayer]);

  return (
    <div className={blockClass} ref={blockRef}>
      <div className={styles.tvFrameWrap}>
        <Image src={TVframe} alt="Loop" />
        <div className={styles.tvBackground}>
          {videoFile != null && (
            <div>
              <video autoPlay loop playsInline muted>
                <source src={videoFile.asset.url} />
              </video>
              <Image src={loopLogo} priority alt="Loop Logo Background Image" />
            </div>
          )}
        </div>
        <div className={styles.headlines}>
          <div className={styles.animate}>
            {content.headlines && (
              <div>
                {content.headlines.map((headline, index) => (
                  <div
                    key={headline._key}
                    className={styles.headline}
                    ref={(element) => (headlineRefs.current[index] = element)}
                  >
                    <h1>{headline.children[0].text}</h1>
                  </div>
                ))}
              </div>
            )}
          </div>
          <h1>{content.headline2}</h1>
        </div>
        {showPlayer && (
          <div className={styles.loopPlayer} ref={playerRef}>
            <Image
              src={loopPlayer}
              layout="fill"
              objectFit="contain"
              alt="Background Image"
            />
          </div>
        )}
        {content.rewards != false && (
          <div className={styles.rewards} onClick={click}>
            <Image
              src={rewardsPromo}
              layout="fill"
              objectFit="contain"
              alt="Foreground Image"
            />
          </div>
        )}
      </div>

      {content.bg_image_desktop ? (
        <>
          <div className={`${styles.background_image} ${styles.desktop}`}>
            <SanityImage image={content.bg_image_desktop} />
          </div>

          <div className={`${styles.background_image} ${styles.mobile}`}>
            <SanityImage image={content.bg_image_mobile} />
          </div>
        </>
      ) : (
        <>
          {content.background === "restaurant" && <Restaurant />}

          {content.background === "retail" && <Retail />}

          {content.background === "studio" && <Studio />}

          {content.background === "home" && <Home />}
        </>
      )}
    </div>
  );
}
