import Image from "next/image";
import styles from "./signage.module.scss";
import Link from "next/link";
import Button from "../../components/button/button";
import TVFrame from "../../public/images/TV_frame_v3.png";
import { PortableText } from "@portabletext/react";
import myPortableTextComponents from "../utilities/portableTextStyle";
import SanityImage from "../../components/utilities/sanityImage";
import { getFile } from "@sanity/asset-utils";
import sanityProject from "../utilities/sanityProject";
import { gsap, ScrollTrigger } from "../utilities/gsap";
import { useRef, useEffect } from "react";

export default function Signage({ content, modalClick, showBtn = true }) {
  let blockClass = `${styles.wrapper}`;
  if (!showBtn) {
    blockClass += ` ${styles.no_cta}`;
  }

  let videoFile = null;
  if (content.video_file) {
    videoFile = getFile(content.video_file, sanityProject);
  }

  const blockRef = useRef();
  const signageRef = useRef();

  useEffect(() => {
    // gsap animations
    gsap.registerPlugin(ScrollTrigger);

    // signage animation on scroll
    gsap.set(signageRef.current, { y: 100 });
    gsap.to(signageRef.current, {
      y: -50,
      scrollTrigger: {
        trigger: blockRef.current,
        start: "top bottom",
        end: "bottom top",
        scrub: 1,
        markers: false,
      },
    });
  }, []);

  return (
    <div className={blockClass} ref={blockRef}>
      <div className={styles.tvWrapper} ref={signageRef}>
        <div className={styles.tvFrame}>
          <div className={styles.frameHold}>
            <Image src={TVFrame} alt="TV Frame Image" />
          </div>
          <div className={styles.tvBG}>
            <div className={styles.mediawrap}>
              {videoFile == null ? (
                <SanityImage image={content.image} alt="Signage Image" />
              ) : (
                <video autoPlay loop playsInline muted controls>
                  <source src={videoFile.asset.url} />
                </video>
              )}
            </div>
          </div>
        </div>

        <div className={styles.tvGradient}></div>
      </div>
      <div className={styles.colWrapper}>
        {content.headline && (
          <div className={styles.header}>
            <h1>{content.headline}</h1>
          </div>
        )}
        <div className={styles.subText}>
          <PortableText
            value={content.text}
            components={myPortableTextComponents}
          />
        </div>
        {showBtn && (
          <div className={styles.button}>
            <Link href="https://am.loop.tv">
              <a target="_blank" rel="noopener noreferrer">
                <Button label="Start Your First Campaign" />
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
